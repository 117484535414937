<template>
  <div class="tile is-ancestor is-parent full-quote-page">
    <div class="tile is-parent">
      <article class="tile is-child box detail-page-tile">
        <div class="columns is-gapless">
          <div class="column">
            <div class="columns is-gapless">
              <div class="column">
                <p class="title">Other Paint Group</p>
                <subtitle-totals :labels="['OPG Total', 'Grand Total']"
                  :totals="[opgTotal, innerValue.totalExGst]"
                  :shows="[true, true]" />
              </div>
              <div class="column">
                <div class="control is-pulled-right">
                  <div v-if="!audanetLock"
                    class="buttons">
                    <button v-if="value.subQuoteNo > 0 && !innerValue.readOnly && !readOnlyView"
                      class="button is-success tooltip is-tooltip-topright"
                      data-tooltip="Adjust Main Quote Items"
                      @click="toggleAvailableItemsMain()">
                      <span class="icon">
                        <i class="mdi"
                          :class="{ 'mdi-adjust' : !isAvailableItemsMainActive, 'mdi-close' : isAvailableItemsMainActive }" />
                      </span>
                      <span>{{ isAvailableItemsMainActive ? 'Done' : 'Adjust' }}</span>
                    </button>
                    <button v-if="!innerValue.readOnly && !readOnlyView"
                      class="button is-primary tooltip is-tooltip-topright"
                      data-tooltip="Add new misc"
                      @click="toggleAvailableItems()">
                      <span class="icon">
                        <i class="mdi"
                          :class="{ 'mdi-plus' : !isAvailableItemsActive, 'mdi-minus' : isAvailableItemsActive }" />
                      </span>
                      <span>{{ isAvailableItemsActive ? 'Close' : 'Add' }}</span>
                    </button>
                    <button v-if="!innerValue.readOnly && !readOnlyView"
                      class="button is-primary tooltip is-tooltip-topright"
                      data-tooltip="Add blank item"
                      @click="addBlankItem">
                      <span class="icon">
                        <i class="mdi mdi-shape-square-plus" />
                      </span>
                    </button>
                  </div>
                  <div v-if="audanetLock && !$showSpinnerRequestCount">
                    <audanet-lock-notification />
                  </div>
                </div>
              </div>
            </div>
            <available-opgs v-if="vehicle && isAvailableItemsActive"
              v-model="items"
              :quote-id="value.quoteId"
              :vehicle-paint-group="vehicle.paintGroup"
              :paint-group-rate="getLabourRate(vehicle.paintGroup).rate"
              :next-line-number="nextLineNumber"
              @item-added="onItemAdded" />
            <available-opgs-main v-if="vehicle && isAvailableItemsMainActive"
              v-model="items"
              :quote-id="value.quoteId"
              :quote-no="value.quoteNo"
              :sub-quote-no="value.subQuoteNo"
              :vehicle-paint-group="vehicle.paintGroup"
              :paint-group-rate="getLabourRate(vehicle.paintGroup).rate"
              :next-line-number="nextLineNumber"
              :quoting-method="value.quotingMethod"
              @item-added="onItemAdded" />
            <div v-if="isAvailableItemsActive || isAvailableItemsMainActive"
              class="is-divider" />
            <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
              :columns="columns"
              :page-index="filter.pageIndex"
              :page-size="filter.pageSize"
              :total-rows="totalRows"
              :is-loading="isTableLoading"
              :sort-column="filter.sortColumn"
              :sort-order="filter.sortOrder"
              :draggable="true"
              :show-pagination="false"
              @pageChanged="onPageChange"
              @sort="sort">
              <draggable v-model="innerValue.opgs"
                :tag="'tbody'"
                v-bind="{'disabled': !isRowDraggable, chosen: 'chosen', ghostClass: 'draggable-ghost', dragClass: 'draggable-drag'}"
                @change="itemMoved"
                @end="endMove">
                <tr v-for="(item, index) in activeItems"
                  :key="item.quoteItemId"
                  :class="[defaultRowColourClass(item), rowDisplayStatus(item, getItemAssessments(item))]"
                  @click="highlightSelected(index, $event)"
                  :data-itemid="item.quoteItemId">
                  <td v-show="$userInfo.isSupportUser"
                    class="has-vertical-middle has-text-right linenumber-input">
                    <vue-numeric v-if="!innerValue.readOnly && $userInfo.isSupportUser && !readOnlyView && !isItemRemoved(item, getItemAssessments(item))"
                      class="input has-text-right"
                      :class="defaultRowColourClass(item)"
                      v-model.number="item.lineNumber"
                      :precision="0"
                      separator="" />
                    <span v-else>{{ item.lineNumber }}</span>
                  </td>
                  <td class="has-vertical-middle itemno-input">
                    <input v-if="!item.deleteOtherItem && !innerValue.readOnly && !readOnlyView && !audanetLock && !isItemRemoved(item, getItemAssessments(item))"
                      class="input"
                      :class="[defaultRowColourClass(item), !isValidItemNo(item.quoteItemId) ? 'is-danger': '']"
                      placeholder="Item No."
                      v-model="item.itemNo">
                    <span v-else>{{ item.itemNo }}</span>
                  </td>
                  <td class="has-vertical-middle itemdesc-input">
                    <input v-if="!item.deleteOtherItem && !innerValue.readOnly && !readOnlyView && !audanetLock && !isItemRemoved(item, getItemAssessments(item))"
                      class="input"
                      :class="[defaultRowColourClass(item), !isValidItemDesc(item.quoteItemId) ? 'is-danger': '']"
                      placeholder="Item Description"
                      v-model="item.itemDesc">
                    <span v-else>{{ item.itemDesc }}</span>
                    <div v-if="item.itemComment"
                      class="help is-primary">
                      <span class="has-text-weight-bold">Comment: </span>
                      <span>{{ item.itemComment }}</span>
                    </div>
                  </td>
                  <td class="has-vertical-middle has-text-centered">
                    <div v-if="!item.deleteOtherItem && !innerValue.readOnly && !readOnlyView && !audanetLock && !isItemRemoved(item, getItemAssessments(item))"
                      class="pretty p-icon p-curve p-smooth m-0 p-bigger">
                      <input v-model="item.reportOnly"
                        type="checkbox"
                        @change="updateTotal(index)">
                      <div class="state p-info">
                        <i class="icon mdi mdi-check" />
                        <label />
                      </div>
                    </div>
                    <span v-else
                      class="icon has-text-info">
                      <i class="mdi mdi-24px"
                        :class="[ item.reportOnly ? 'mdi-check' : '' ]" />
                    </span>
                  </td>
                  <td class="has-vertical-middle has-text-centered is-content-width">
                    <div v-if="vehicle && !item.deleteOtherItem && !innerValue.readOnly && !readOnlyView && !audanetLock && !isItemRemoved(item, getItemAssessments(item))"
                      class="select">
                      <select v-model="item.opgCode"
                        :class="defaultRowColourClass(item)"
                        @change="getOpgRate(item, index)">
                        <option v-for="(paintGroup) in paintGroupTypes"
                          :value="paintGroup"
                          :key="paintGroup"
                          :class="{ 'has-text-success' : paintGroup === vehicle.paintGroup }">{{ paintGroup }}</option>
                      </select>
                    </div>
                    <span v-else>{{ item.opgCode }}</span>
                  </td>
                  <td class="has-vertical-middle has-text-right money-input">
                    <vue-numeric v-if="!innerValue.readOnly && !readOnlyView && !audanetLock && !isItemRemoved(item, getItemAssessments(item))"
                      class="input has-text-right"
                      :class="defaultRowColourClass(item)"
                      v-model.number="item.hourValue"
                      :minus="innerValue.subQuoteNo > 0"
                      :precision="2"
                      @input="updateValue(index, item)" />
                    <span v-else>{{ item.hourValue | formatNumber($userInfo.locale) }}</span>
                  </td>
                  <td class="has-vertical-middle has-text-right">{{ item.dollarValue | formatNumber($userInfo.locale) }}</td>
                  <td v-if="innerValue.quotingMethod === quotingMethodTypes.Hour"
                    class="has-vertical-middle has-text-right money-input">
                    <vue-numeric v-if="!item.deleteOtherItem && !innerValue.readOnly && !readOnlyView && !audanetLock && !isItemRemoved(item, getItemAssessments(item))"
                      class="input has-text-right"
                      :class="[defaultRowColourClass(item), item.rate !== getDefaultRate(item, index) ? 'is-warning' : '']"
                      v-model.number="item.rate"
                      :min="0"
                      :precision="2"
                      @input="updateValue(index, item)" />
                    <span v-else>{{ item.rate | formatNumber($userInfo.locale) }}</span>
                  </td>
                  <td v-show="$userInfo.isSupportUser"
                    class="has-vertical-middle itemstatus-input">
                    <input v-if="!innerValue.readOnly && !readOnlyView && !isItemRemoved(item, getItemAssessments(item))"
                      class="input is-capitalized"
                      v-model="item.itemStatus">
                    <span v-else>{{ item.itemStatus }}</span>
                  </td>
                  <td class="has-vertical-middle has-text-centered is-content-width">
                    <div class="icon-container  items-center">
                      <supplementary-icon v-if="innerValue.quoteAssessments.length > 0"
                        :item="item"
                        :item-assessments="getItemAssessments(item)"
                        :next-assessment-number="nextSupplemetryNo"
                        :quoting-method="innerValue.quotingMethod"
                        :icon-size="4"
                        class="icon" />
                      <button v-if="!audanetLock && !isItemRemoved(item, getItemAssessments(item)) && (item.isDeleted === false || item.deleted === false)"
                        class="button is-danger is-small is-inverted"
                        @click="deleteItem(item, index)"
                        :disabled="innerValue.readOnly || readOnlyView || isItemRemoved(item, getItemAssessments(item))">
                        <span class="icon is-medium">
                          <i class="mdi mdi-delete mdi-24px" />
                        </span>
                      </button>
                      <button class="button is-primary is-small is-inverted item-drag-handle pr-0 pl-1"
                        :disabled="innerValue.readOnly || readOnlyView">
                        <span class="icon is-medium">
                          <i class="mdi mdi-drag-vertical mdi-24px" />
                        </span>
                      </button>
                    </div>
                  </td>
                </tr>
              </draggable>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <span icon="icon is-large">
                      <i class="mdi mdi-48px mdi-emoticon-sad" />
                    </span>
                    <p>Nothing</p>
                  </div>
                </section>
              </template>
              <template slot="footer">
                <tr>
                  <th class="has-text-right has-text-success"
                    :colspan="totalColSpan">Totals</th>
                  <th class="has-text-right has-text-success"
                    :class="[ innerValue.opgTotal >= 0 ? 'has-text-success' : 'has-text-danger' ]">{{ innerValue.opgTotal | formatCurrency($userInfo.locale) }}</th>
                  <th colspan="100%" />
                </tr>
              </template>
            </bulma-table>
          </div>
        </div>
      </article>
    </div>
    <totals-floating-widget :active.sync="isFloatingWidgetActive"
      title="OPG Totals"
      :totals="opgTotal"
      :total-ex-gst="innerValue.totalExGst" />
  </div>
</template>

<script>
// import InsurerService from '@/views/insurer/InsurerService'
import BulmaTable from '@/components/BulmaTable'
import { NumberFiltersMixin } from '@/components/mixins/filters'
import { OpgColumns } from './columns'
import { QuotingMethodTypes, PaintGroupTypes, LabourTimeTypes, QuoteItemStatusTypes, EventHubTypes, ItemCategoryTypes } from '@/enums'
// import _orderBy from 'lodash/orderBy'
import VueNumeric from '@/components/VueNumeric'
import { TotalsFloatingWidget, AvailableOpgs, AvailableOpgsMain, SupplementaryIcon } from './components'
import { QuoteAssessmentMixin, QuoteItemValidationMixin, QuoteOpgMixin } from './mixins'
import { QuoteItemModel, QuoteLabourRateModel } from '@/classes/viewmodels'
import draggable from 'vuedraggable'
import _cloneDeep from 'lodash/cloneDeep'
import SubtitleTotals from '@/components/SubtitleTotals'

export default {
  name: 'QuoteOpg',
  components: {
    BulmaTable,
    VueNumeric,
    TotalsFloatingWidget,
    AvailableOpgs,
    AvailableOpgsMain,
    SupplementaryIcon,
    SubtitleTotals,
    draggable
  },
  mixins: [NumberFiltersMixin, QuoteItemValidationMixin, QuoteOpgMixin, QuoteAssessmentMixin],
  props: {
    value: null,
    vehicle: null,
    nextLineNumber: {
      type: Number,
      default: 0
    },
    readOnlyView: {
      type: Boolean,
      default: false
    },
    audanetLock: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      innerValue: null,
      // items: null,
      selectedRow: null,
      isTableLoading: false,
      filter: {
        sortColumn: OpgColumns[1].name,
        sortOrder: OpgColumns[1].defaultOrder,
        pageIndex: 1,
        pageSize: 100
      },
      isFloatingWidgetActive: true,
      isAvailableItemsActive: false,
      isAvailableItemsMainActive: false
    }
  },
  computed: {
    columns() {
      const c = _cloneDeep(OpgColumns)
      if (this.value.quotingMethod === QuotingMethodTypes.Dollar) {
        c[5].title = 'Amount'
        c.splice(7, 1)
      }
      c[1].isVisible = this.$userInfo.isSupportUser
      c[c.length - 2].isVisible = this.$userInfo.isSupportUser
      return c
    },
    paintGroupTypes() {
      return PaintGroupTypes
    },
    activeItems() {
      return this.items.filter((i) => this.isItemDeleted(i, this.getItemAssessments(i)) === false)
    },
    isNtar() {
      return (
        this.innerValue &&
        (this.innerValue.labourType === LabourTimeTypes.NTAR ||
          this.innerValue.labourType === LabourTimeTypes.LTAR ||
          this.innerValue.labourType === LabourTimeTypes.eMTA)
      )
    },
    totalRows() {
      return this.activeItems.length
    },
    items() {
      if (this.innerValue) {
        return this.innerValue.opgs
      }
    },
    totalColSpan() {
      return this.columns.length - (this.$userInfo.isSupportUser ? 5 : 6)
    },
    isRowDraggable() {
      return !this.innerValue.readOnly
    },
    quotingMethodTypes() {
      return QuotingMethodTypes
    },
    itemsAssessments() {
      return this.getOpgItemsAssessments()
    },
    nextSupplemetryNo() {
      const nextSuppNo = Math.max(...this.value.quoteAssessments.map((i) => i.subQuoteNo)) + 1
      return nextSuppNo
    }
  },
  watch: {
    // value: {
    //   handler: function(newVal, oldVal) {
    //     this.innerValue = newVal
    //     this.filter.sortColumn = ''
    //     this.filter.sortOrder = ''
    //     // this.items = _orderBy(this.innerValue.opgs.filter(i => !i.deleted), [this.filter.sortColumn], [this.filter.sortOrder])
    //   },
    //   deep: true
    // },
    innerValue: {
      handler: function(newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  created() {
    this.innerValue = this.value
    // this.items = _orderBy(this.innerValue.opgs.filter(i => !i.deleted), [this.filter.sortColumn], [this.filter.sortOrder])
  },
  mounted() {
    this.$eventHub.$on(EventHubTypes.EntitySaved, () => {
      this.innerValue = this.value
    })
    this.$v.entity.opgs.$touch()
  },
  beforeDestroy() {
    this.$eventHub.$off(EventHubTypes.EntitySaved)
  },
  methods: {
    highlightSelected(index, event) {
      // this.selectedRow = index
    },
    onPageChange(pageIndex) {},
    sort(name, order) {
      this.filter.sortColumn = name
      this.filter.sortOrder = order
      // this.items = _orderBy(this.innerValue.opgs.filter(i => !i.deleted), [this.filter.sortColumn], [this.filter.sortOrder])
    },
    updateValue(index, item) {
      // if (item && item.deleteOtherItem && item.hourValue > 0) {
      //   item.hourValue = item.hourValue * -1
      // }
      if (this.value.quotingMethod === QuotingMethodTypes.Dollar) {
        item.dollarValue = item.hourValue
      } else {
        item.dollarValue = roundAwayFromZero(item.hourValue * item.rate)
      }
      this.updateTotal()
    },
    getOpgRate(item, index) {
      if (this.value.quotingMethod === QuotingMethodTypes.Hour) {
        let rate = this.isNtar ? this.innerValue.rates[0] : this.innerValue.rates.find((r) => r.labourCodeId === item.opgCode)
        if (!rate) {
          const labourType = this.$labourTypes.find((t) => t.labourTypeCode === PaintGroupTypes.PAINT)
          const newRate = {
            quoteId: this.innerValue.quoteId,
            labourCodeId: item.opgCode,
            labourTypeId: labourType.labourTypeId,
            rate: this.isNtar ? this.innerValue.shopRate : 0,
            modifiedBy: '',
            modifiedDate: null,
            createdBy: '',
            createdDate: null,
            isNew: true,
            quoteVersion: 0,
            deleted: false
          }
          this.innerValue.rates.push(newRate)
          rate = newRate
        }
        // this.items[index].rate = rate.rate
        // this.items[index].dollarValue = this.items[index].hourValue * this.items[index].rate
        item.rate = rate.rate
        item.dollarValue = roundAwayFromZero(this.items[index].hourValue * this.items[index].rate)
      }
      this.$emit('opg-code-changed')
      this.updateTotal()
    },
    getDefaultRate(item, index) {
      let rate = this.isNtar ? this.innerValue.rates[0] : this.innerValue.rates.find((r) => r.labourCodeId === item.opgCode)
      return rate.rate
    },
    updateTotal(index) {
      this.isFloatingWidgetActive = true
    },
    deleteItem(item, index) {
      let originalItemStatus = item.itemStatus
      if (item.isNew) {
        const itemIndex = this.innerValue.opgs
          .map(function (obj) {
            return obj.quoteItemId
          })
          .indexOf(item.quoteItemId)
        if (itemIndex >= 0) {
          this.innerValue.opgs.splice(itemIndex, 1)
          // this.items.splice(index, 1)
        }
      } else {
        item.deleted = true
        item.isDeleted = true
        item.itemStatus = QuoteItemStatusTypes.Deleted
        item.originalItemStatus = originalItemStatus
      }
      this.updateTotal()
      this.$snackbar.open({
        message: `Item <span class="is-italic">${item.itemDesc}</span> deleted`,
        type: 'is-danger',
        position: 'is-bottom',
        actionText: 'Undo',
        onAction: () => {
          if (item.isNew) {
            this.innerValue.opgs.splice(this.innerValue.opgs.length, 1, item)
            // this.items.splice(this.items.length, 1, item)
          } else {
            item.deleted = false
            item.isDeleted = false
            item.itemStatus = originalItemStatus
          }
          this.updateTotal()
        }
      })
    },
    restoreItem(item, index) {
      item.deleted = false
      item.isDeleted = false
      //TODO: must set item status to original item status
      this.updateTotal()
    },
    toggleAvailableItems() {
      this.isAvailableItemsMainActive = false
      this.isAvailableItemsActive = !this.isAvailableItemsActive
    },
    toggleAvailableItemsMain() {
      this.isAvailableItemsActive = false
      this.isAvailableItemsMainActive = !this.isAvailableItemsMainActive
    },
    onItemAdded(newItem) {
      this.filter.sortColumn = ''
      this.filter.sortOrder = ''
      this.innerValue.lines = newItem.lineNumber + 1
      this.innerValue.opgs.splice(this.innerValue.opgs.length, 1, newItem)
      // this.items = _orderBy(this.innerValue.opgs.filter(i => !i.deleted), [this.filter.sortColumn], [this.filter.sortOrder])
      this.$toast.open({
        message: 'Item added',
        type: 'is-success',
        position: 'is-bottom',
        queue: false
      })
    },
    addBlankItem() {
      let newItem = new QuoteItemModel(this.innerValue.quoteId, '', '', ItemCategoryTypes.OPG)
      newItem.lineNumber = this.nextLineNumber
      newItem.itemNo = this.$filters.pad(newItem.lineNumber, 4)
      newItem.hourValue = 0
      newItem.rate = this.innerValue.rates.find((r) => r.labourCodeId === this.vehicle.paintGroup).rate
      newItem.dollarValue = 0
      newItem.opgCode = this.vehicle.paintGroup
      newItem.sortNo = this.innerValue.opgs.length ? Math.max(...this.innerValue.opgs.map((i) => i.sortNo)) + 1 : 1
      this.innerValue.opgs.splice(this.innerValue.opgs.length, 1, newItem)
      this.innerValue.lines = this.nextLineNumber + 1
      this.$toast.open({
        message: 'Item added',
        type: 'is-success',
        position: 'is-bottom',
        queue: false
      })
    },
    itemMoved(event) {
      // console.log(event.moved)
      let sortNo = Math.min(...this.innerValue.opgs.map((i) => i.sortNo))
      this.innerValue.opgs.forEach((item) => {
        item.sortNo = sortNo
        sortNo++
      })
    },
    getLabourRate(code) {
      if (!this.innerValue.rates) {
        return {}
      }
      var rate = this.innerValue.rates.find((i) => i.labourCodeId === code)
      if (!rate) {
        const labourType = this.$labourTypes.find((t) => t.labourTypeCode === code) || this.$labourTypes.find((t) => t.labourTypeCode === 'PAINT')
        console.log(code, labourType)
        let newRate = new QuoteLabourRateModel(this.innerValue.quoteId, code, labourType.labourTypeId, this.isNtar ? this.innerValue.shopRate : 0)
        this.innerValue.rates.push(newRate)
        rate = newRate
      }
      return rate
    },
    endMove(event) {},
    getOpgItemsAssessments() {
      return this.getItemsAssessments(this.items, this.innerValue.quoteAssessments)
    },
    getItemAssessments(item) {
      const itemAssessments = this.itemsAssessments.find((i) => i.quoteItemId === item.quoteItemId)
      if (itemAssessments) {
        return itemAssessments.assessments
      }
      return []
    }
  }
}
</script>

<style lang="scss" scoped>
.linenumber-input {
  width: 60px;
}
.itemno-input {
  max-width: 2.5em;
}
.itemdesc-input {
  min-width: 20em;
}
.money-input {
  max-width: 6.5em;
}
.itemstatus-input {
  width: 50px;
}
</style>
